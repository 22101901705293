<template>
  <div>
    <Menu />

    <section>
      <div class="container-fluid g-0 pt-3">
        <div class="row g-0">
          <div class="col text-center">
            <div class="developments-banner">
              <div class="row">
                <div class="col-lg-6 mx-auto align-self-center switch-white pb-5">
                  <h1 class="display-2 pb-5">New Developments</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-light mt-5 mb-5 pb-5">
      <div class="container g-5">
        <div class="row pt-5">
          <div class="col-md-12 pb-5 text-center">
            <p>Introducing</p>
            <h2>Drakenzicht</h2>
            <p class="text-black">Life is beautiful at Drakenzicht Estate. Nestled within the Klein Drakenstein mountains in Paarl, one of the Cape’s most beautiful areas, this luxury “green” estate seamlessly blends beautiful natural scenery with secure family estate living. Eco-friendly and luxurious, Drakenzicht assures residents that they can enjoy safe, modern living in an idyllic location.</p>
          </div> 
          <div class="col-md-4 text-center pb-5">
            <img src="https://legaro.co.za/wp-content/uploads/2024/05/title-slide03b.jpg" class="img img-fluid">
          </div>
          <div class="col-md-4 text-center pb-5">
            <img src="https://legaro.co.za/wp-content/uploads/2024/05/title-slide02b.jpg" class="img img-fluid">
          </div>
          <div class="col-md-4 text-center pb-5">
            <img src="https://legaro.co.za/wp-content/uploads/2024/05/title-slide01b.jpg" class="img img-fluid">
          </div>   
        </div>
      </div>
      <center><a type="button" class="btn cta-btn m-1" data-bs-toggle="modal" data-bs-target="#downloadModal">Download Brochure</a></center>
    </section>

    <section class="mb-5">
      <div class="container g-5">
        <div class="row pt-5">
          <div class="col-md-12 pb-5 text-center">
            <p>Introducing</p>
            <h2>Avec la Terre</h2>
            <p class="text-black">Discover the epitome of luxury living at our exceptional lifestyle estate, where comfort, sustainability, privacy and nature intertwine. Indulge in an unparalleled lifestyle that cherishes ecological balance, while enjoying the finest amenities and breath-taking natural surroundings.</p>
          </div> 
          <div class="col-md-4 text-center pb-5">
            <img src="https://aveclaterre.co.za/images/properties/res-3-front-view.jpg" class="img img-fluid">
          </div>
          <div class="col-md-4 text-center pb-5">
            <img src="https://aveclaterre.co.za/images/properties/resi-1-and-2-rear-view.jpg" class="img img-fluid">
          </div>
          <div class="col-md-4 text-center pb-5">
            <img src="https://aveclaterre.co.za/images/properties/resi-3-rear-view.jpg" class="img img-fluid">
          </div>   
        </div>
      </div>
      <center><a type="button" class="btn cta-btn m-1" data-bs-toggle="modal" data-bs-target="#downloadModal">Download Brochure</a></center>
    </section>

    <Footer />
  </div>
</template>
<script>

import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Menu, Footer 
  },
  mounted() {
  },
  data() {
    return {
      data: {
        listings: []
      }
    }
  },
  methods: {

  }
}
</script>